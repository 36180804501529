import React from "react"
import nanostyled from "nanostyled"

import { Heading } from "./heading.component"
import { HeadingLinkSvg } from "./heading-link-svg.component"

const SectionHeadingLink = nanostyled("a", {
  position: "absolute",
  marginLeft: "nl4",
})

export const SectionHeading = ({ children, color, margin, hasLink = true }) => {
  const link = formatLink(children)

  return (
    <>
      <Heading
        color={color || "blue"}
        tag={2}
        size="f2-ns f3"
        margin={margin || "mt0 mb3"}
        id={link}
        className={"hide-child"}
      >
        {hasLink && (
          <SectionHeadingLink href={"#" + link} className={"child"}>
            <HeadingLinkSvg title={link} />
          </SectionHeadingLink>
        )}
        {children}
      </Heading>
      <hr className={"tc center bg-green w3 pt1 mt3 mb3 bn"} />
    </>
  )
}

const formatLink = children => {
  if (!children) return ""

  const titleNoSpecialChars = children
    .trim()
    .replace(/[^a-zA-Z ]/g, "")
    .split(" ")
  if (titleNoSpecialChars.length === 1)
    return titleNoSpecialChars[0].toLowerCase()
  else return titleNoSpecialChars.join("-").toLowerCase()
}
