import { useState, useEffect } from "react"
import { request } from "../../services/helpers"

const useFetch = url => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await request(url)
      setData(response)
      setLoading(false)
    }
    fetchUrl()
  }, [url])
  return [data, loading]
}

export { useFetch }
