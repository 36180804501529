import React from "react"
import PropTypes from "prop-types"

import { SmartLink } from "./smart-link.component"

export const CallToAction = ({ url, children, ...styleProps }) => {
  const CTAStyleProps = {
    color: "black",
    padding: "ph4 pv3",
    margin: "mv3",
    border: "ba bw1",
    bg: "bg-transparent",
    size: "f4-ns f5",
    align: "",
    font: "open-sans",
    base: "no-underline grow v-mid tc dib",
    ...styleProps,
  }
  const link = (
    <SmartLink to={url} {...CTAStyleProps}>
      {children}
    </SmartLink>
  )

  return styleProps.align === "center" ? (
    <div className="flex justify-center">{link}</div>
  ) : (
    link
  )
}

CallToAction.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  border: PropTypes.string,
  align: PropTypes.string,
  bg: PropTypes.string,
  size: PropTypes.string,
}
