import React from "react"
import PropTypes from "prop-types"

import {
  getImageSrcFromDescription,
  getFormattedDate,
} from "../../services/posts.service"

export const BlogPost = ({ post }) => {
  const imageSrc = getImageSrcFromDescription(post)
  return (
    <div className="ma2 flex flex-column flex-column-m justify-around-ns justify-center w-100 h-100 tl">
      <a href={post.link[0]}>
        {imageSrc && <img src={imageSrc} alt={post.title} />}
      </a>
      <a href={post.link[0]}>
        <h3 className="mv2">{post.title}</h3>
      </a>
      <p className="mv2">{getFormattedDate(post.pubDate)}</p>
    </div>
  )
}

BlogPost.propTypes = {
  post: PropTypes.shape({
    title: [],
    description: [],
    link: [],
    pubDate: [],
  }),
}
