import React from "react"

import { SectionHeading, SectionBlock } from "../ui"
import { RenderMarkdown } from "../core"
import {
  CMS_COMPONENTS_SECTION_BODY,
  CMS_SCOPE,
} from "../../cms/cms-components.constants"

export const ContentSectionImage = ({
  image,
  section,
  bg,
  color,
  hasLink,
  ...styleProps
}) => {
  const { title, body } = section
  return (
    <SectionBlock bg={bg} color={color} {...styleProps}>
      {title && (
        <SectionHeading
          color={color === "white" ? color : undefined}
          hasLink={hasLink}
        >
          {title}
        </SectionHeading>
      )}
      <div className="flex flex-row-ns flex-column">
        <div className="w-50-ns w-100 flex justify-center align-start">
          <img src={image} />
        </div>
        <div className="w-50-ns w-100">
          <RenderMarkdown
            md={body}
            components={CMS_COMPONENTS_SECTION_BODY}
            scope={CMS_SCOPE}
          />
        </div>
      </div>
    </SectionBlock>
  )
}
