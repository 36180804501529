import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import { Navbar, Footer } from "./components/layout"

import "tachyons/css/tachyons.min.css"
import "./app.css"

export const AppLayout = props => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <div>
          <Helmet>
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/img/og-image.jpg" />
          </Helmet>
          <Navbar />
          <div
            className="tc pv4 blue i libre-baskerville"
            style={{ backgroundColor: "rgb(199, 239, 219, 0.5)" }}
          >
            An update for our community: Bridge will be pausing operations
            indefinitely. You can read more about it{" "}
            <a href="https://medium.com/@itsbridgeschool/an-update-for-our-community-bf576d6b741a">
              here
            </a>
            .
          </div>
          <main>{props.children}</main>
          <Footer />
        </div>
      )}
    />
  )
}
