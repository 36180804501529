import React from "react"
import PropTypes from "prop-types"
import nanostyled from "nanostyled"

import { ProfileCard } from "../ui"

const TeamDiv = nanostyled("div", {
  padding: "pb5 pt4",
  width: "w-100",
  base:
    "justify-center mw8 flex flex-column flex-row-ns tc center flex-wrap-ns",
})

const memberColors = {
  0: "light-blue",
  1: "pink",
  2: "blue",
  3: "green",
}

export const Team = ({ team, ...styleProps }) => (
  <TeamDiv {...styleProps}>
    {team.map((member, i) => {
      const color = memberColors[i % 4]
      return (
        <ProfileCard profile={member} color={color} key={`profile-card${i}`} />
      )
    })}
  </TeamDiv>
)

Team.propTypes = {
  team: PropTypes.arrayOf(
    PropTypes.shape({
      image: "",
      name: "",
      title: "",
      twitter: "",
      linkedIn: "",
    })
  ),
}
