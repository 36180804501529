import React from "react"

import {
  ColumnList,
  Heading,
  CallToAction,
  SmartLink,
  Quote,
  Attribution,
  Avatar,
} from "../components/ui"

import { Testimonial, Video } from "../components/layout"

export const CMS_SCOPE = {
  Avatar: props => <Avatar {...props} margin="mr-auto ml-auto mb2 mt5" />,
  Attribution: props => <Attribution {...props} />,
  CallToAction: props => <CallToAction {...props} />,
  Testimonial: props => (
    <Testimonial bg="bg-transparent" border="" padding="pt5" {...props} />
  ),
  Video: props => <Video {...props} />,
}

// default mappings
export const CMS_COMPONENTS = {
  ul: props => <ColumnList {...props} />,
  h1: props => <Heading tag={1} {...props} />,
  h2: props => <Heading tag={2} {...props} />,
  h3: props => <Heading tag={3} {...props} />,
  h4: props => <Heading tag={4} {...props} />,
  h5: props => <Heading tag={5} {...props} />,
  h6: props => <Heading tag={6} {...props} />,
  blockquote: props => <Quote {...props} />,
  a: props => <SmartLink {...props} />,
}

// more specific mappings based on defaults
export const CMS_COMPONENTS_SECTION_BODY = {
  ...CMS_COMPONENTS,
  h1: props => <Heading tag={1} align="tl" {...props} />,
  h2: props => <Heading tag={2} align="tl" {...props} />,
  h3: props => <Heading tag={3} align="tl" {...props} />,
  h4: props => <Heading tag={4} align="tl" {...props} />,
  h5: props => <Heading tag={5} align="tl" {...props} />,
  h6: props => <Heading tag={6} align="tl" {...props} />,
}
