import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import SEO from "../components/seo"
import { safelyGetSiteConfig } from "../utils"

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          navigation {
            label
            linkURL
          }
        }
      }
    }
  }
`

const NotFoundPage = ({ siteLinks }) => (
  <>
    <SEO title="404: Not found" />
    <section className="bg-near-white open-sans pb5">
      <header className="tc ph5-l ph4 lh-copy">
        <h1 className="f1-ns f-headline-l code mb3 fw9 dib tracked-tight green open-sans">
          (╯°□°)╯︵ ┻━┻
        </h1>
        <h2 className="tc f1-ns fw1 blue ph5-l">
          Sorry, we can't find the page you're looking for.
        </h2>
      </header>
      <p className="fw1 i tc mt4 mt5-l f4 f3-l blue">
        Are you looking for one of these?
      </p>
      <ul className="list tc pl0 w-100 pb4 mb0">
        {siteLinks.map((link, i) => (
          <li className="dib">
            <Link
              className="f5 f4-ns no-underline pink db pv2 ph3 hover-green open-sans"
              to={link.linkURL}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  </>
)

const NotFoundTemplate = () => (
  <StaticQuery
    query={query}
    render={data => {
      const navigation = safelyGetSiteConfig(data.sitePage).navigation || []
      return <NotFoundPage siteLinks={navigation} />
    }}
  />
)

export default NotFoundTemplate
