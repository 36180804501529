import React from "react"
import { Link as GatsbyLink } from "gatsby"
import nanostyled from "nanostyled"

// Adapted from:
// https://www.gatsbyjs.org/docs/gatsby-link/#use-link-only-for-internal-links
export const SmartLink = ({ children, to, activeClassName, ...other }) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with one slash or a hash tag
  const internal = /^\/(?!\/|#)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  const element = internal ? GatsbyLink : "a"
  // Create props appropriate to either gatsby or base links
  const linkProps = internal
    ? {
        to,
        activeClassName,
      }
    : {
        href: to,
      }

  // Create the element as either a gatsby link or an a tag
  const StyledLink = nanostyled(element, {
    color: "",
    padding: "",
    margin: "",
    border: "",
    size: "",
    bg: "",
    base: "",
    font: "",
  })

  return (
    <StyledLink {...linkProps} {...other}>
      {children}
    </StyledLink>
  )
}
