import React from "react"
import nanostyled from "nanostyled"

const StyledInput = nanostyled("input", {
  width: "w-60-l",
  height: "",
  margin: "mr3-ns mb3 mb0-ns",
  padding: "pa3",
  font: "open-sans",
  base: "shadow-4 bw0",
})

export const Input = ({ type = "text", ...styleProps }) => (
  <StyledInput type={type} {...styleProps} />
)
