import React from "react"
import { StaticQuery } from "gatsby"

import { ContentSectionImage } from "./"
import { safelyGetSiteConfig } from "../../utils"

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          historyOfBridge {
            title
            image
            body
          }
        }
      }
    }
  }
`

const HistoryOfBridgeTemplate = ({ section, image }) => (
  <ContentSectionImage image={image} section={section} />
)

export const HistoryOfBridge = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const { historyOfBridge } = safelyGetSiteConfig(data.sitePage)
        return (
          <HistoryOfBridgeTemplate
            image={historyOfBridge.image}
            section={{
              title: historyOfBridge.title,
              body: historyOfBridge.body,
            }}
          />
        )
      }}
    />
  )
}
