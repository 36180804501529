export const safelyGetFmKey = (pageContext, key) =>
  safelyGetFrontMatter(pageContext)
    ? safelyGetFrontMatter(pageContext)[key]
    : null

export const safelyGetFrontMatter = pageContext =>
  pageContext && pageContext.frontmatter ? pageContext.frontmatter : {}

// General, consider moving
export const withFallback = (value, fallback) => (value ? value : fallback)

export const unpackMetadata = (meta = []) =>
  meta.reduce((prev, curr) => {
    const keys = Object.keys(curr)
    prev[curr[keys[0]]] = curr[keys[1]]
    return prev
  }, {})

export const safelyGetSiteConfig = page =>
  page && page.context ? safelyGetFrontMatter(page.context) : {}

export const safelyCheckIfPageIsHidden = page => {
  const frontmatter = safelyGetFrontMatter(page)
  const isProduction = process.env.GATSBY_DEPLOY_CONTEXT === "production"

  return (
    isProduction &&
    frontmatter.hasOwnProperty("isLive") &&
    frontmatter.isLive === false
  )
}
