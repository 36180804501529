import React from "react"

import { SectionHeading, SectionBlock, BlogPost } from "../ui"
import { CallToAction } from "../ui/call-to-action.component"
import { useFetch } from "../hooks"
import { POSTS_URL } from "../../services/posts.service"

export const BlogPostContainer = () => {
  const [data, isLoading] = useFetch(POSTS_URL)
  return isLoading ? (
    ""
  ) : (
    <SectionBlock align="tc">
      <SectionHeading>Our Blog</SectionHeading>
      <div className="flex flex-column-m ph4-ns ph2-m mb3">
        {data &&
          data.slice(0, 3).map(post => {
            return <BlogPost post={post} />
          })}
      </div>
      <CallToAction
        id="partner-cta-top"
        type="external"
        color="pink"
        margin="mt2 ml4 mr4"
        bg="bg-white"
        url="https://medium.com/@itsbridgeschool"
      >
        See More
      </CallToAction>
    </SectionBlock>
  )
}
