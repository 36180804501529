import defaultBlogImage from "../images/BlogListing-2.png"

export const POSTS_URL =
  process.env.NODE_ENV === "development"
    ? "/functions/posts"
    : "/.netlify/functions/posts"

export const getFormattedDate = pubDate => {
  let d = new Date(pubDate).toDateString()
  d = d.split(" ")
  return `${d[1]} ${d[2]}, ${d[3]}`
}

export const getImageSrcFromDescription = post => {
  const { description } = post
  const el = document.createElement("div")
  el.innerHTML = description
  return el.getElementsByTagName("img")[0]
    ? el.getElementsByTagName("img")[0].src
    : defaultBlogImage
}
