import React from "react"
import PropTypes from "prop-types"

import { SectionHeading, SectionBlock } from "../ui"
import { RenderMarkdown } from "../core"
import {
  CMS_COMPONENTS_SECTION_BODY,
  CMS_SCOPE,
} from "../../cms/cms-components.constants"

export const ContentSection = ({
  section,
  bg,
  color,
  hasLink,
  ...styleProps
}) => {
  const { title, body } = section
  return (
    <SectionBlock bg={bg} color={color} {...styleProps}>
      {title && (
        <SectionHeading
          color={color === "white" ? color : undefined}
          hasLink={hasLink}
        >
          {title}
        </SectionHeading>
      )}
      <RenderMarkdown
        md={body}
        components={CMS_COMPONENTS_SECTION_BODY}
        scope={CMS_SCOPE}
      />
    </SectionBlock>
  )
}

ContentSection.defaultProps = {
  section: {
    title: "",
    body: "",
    testimonial: {},
  },
}

ContentSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
}
