import React from "react"
import nanostyled from "nanostyled"

const withColor = (color = "green") =>
  nanostyled("button", {
    color: `${color} b--${color}`,
    fontSize: "f4-ns f5",
    bg: "",
    height: "",
    padding: "ph4 pv3",
    base: "open-sans outline-0 no-underline grow v-mid ba bw1",
  })

export const Button = ({
  onClick,
  children,
  color,
  disabled,
  ...styleProps
}) => {
  const StyledButton = withColor(color)

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      color={disabled ? "grey" : color}
      {...styleProps}
    >
      {children}
    </StyledButton>
  )
}
