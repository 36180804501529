import React from "react"
import nanostyled from "nanostyled"

import { RenderMarkdown } from "../core"
import { CMS_SCOPE, CMS_COMPONENTS } from "../../cms/cms-components.constants"

const PageHeader = nanostyled("header", {
  color: "",
  base:
    "cover center lh-2 flex bg-center cover items-center justify-center ph6-l ph4 h6-ns",
})

export const PageHero = ({ backgroundImage, md, textColor }) => (
  <PageHeader
    color={textColor}
    style={{ backgroundImage: `url(${backgroundImage}` }}
  >
    <RenderMarkdown components={CMS_COMPONENTS} scope={CMS_SCOPE} md={md} />
  </PageHeader>
)
