import React, { useState } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import classNames from "classnames"

import { MobileNavButton } from "../ui"
import { safelyGetSiteConfig } from "../../utils"
import bridgeLogo from "../../images/bridge-logo.svg"

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          navigation {
            label
            linkURL
          }
        }
      }
    }
  }
`

export const Navbar = () => (
  <StaticQuery
    query={query}
    render={data => {
      const navigation = safelyGetSiteConfig(data.sitePage).navigation || []
      return <Navigation navlinks={navigation} />
    }}
  />
)

const Navigation = ({ navlinks }) => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const navMenuClassNames = classNames(
    "flex flex-column mobile-menu flex-row-ns items-baseline v-mid tr pa3",
    {
      "dn-toggle": !showMobileNav,
    }
  )

  return (
    <nav className="w-100 flex justify-center bg-white-70 bb b--black-10 nav-height z-2 relative">
      <MobileNavButton onToggleNav={() => setShowMobileNav(!showMobileNav)} />
      <div className="mw8 flex items-center w-100 justify-between">
        <div className="v-mid pa3">
          <Link to="/" className="dib w3 h2 pa1">
            <img alt="bridge logo" src={bridgeLogo} />
          </Link>
        </div>
        <div className={navMenuClassNames}>
          {navlinks.map((navlink, idx) => (
            <Link
              key={"navlink" + idx}
              className="mobile-menu-li flex items-start no-underline w-100 w-auto-ns"
              to={navlink.linkURL}
            >
              <span className="f6-ns f7 fw4 db w-100 w-auto-ns tl blue hover-pink pv3 mh2  bw1 pv2-ns ph3 mb2 mb0-ns">
                {navlink.label}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}
