const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

export const request = async (url, options) => {
  try {
    const response = await fetch(url, options)
    handleErrors(response)
    return response.json()
  } catch (error) {}
}
