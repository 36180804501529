import React from "react"

export const MobileNavButton = ({ onToggleNav }) => (
  <button
    onClick={onToggleNav}
    className="dn-ns db"
    id="mobile-nav-menu-button"
  >
    <svg
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="icon-shape">
          <path
            d="M0,3 L20,3 L20,5 L0,5 L0,3 Z M0,9 L20,9 L20,11 L0,11 L0,9 Z M0,15 L20,15 L20,17 L0,17 L0,15 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  </button>
)
