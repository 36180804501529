import React from "react"
import PropTypes from "prop-types"
import nanostyled from "nanostyled"

import { Avatar, Heading } from "../ui"

const Card = nanostyled("div", {
  margin: "mt2 mb3 mh3",
  color: "black",
  bg: "bg-near-white",
  base:
    "mw5-ns br3 ba b--black-05 pa2 pa4-ns flex-auto flex flex-column justify-between",
})

export const ProfileCard = ({ profile, color, ...styleProps }) => {
  const { image, name, title, twitter, linkedIn } = profile

  return (
    <Card {...styleProps}>
      <header>
        {image ? (
          <Avatar
            src={image}
            margin="mr-auto ml-auto mb2"
            borderColor={color}
          />
        ) : null}
        <Heading tag="2" size="f4" margin="mb2" color="black">
          {name}
        </Heading>
        <Heading
          tag="3"
          size="f5"
          color={color}
          margin="mt0"
          className="i fw4 libre-baskerville"
        >
          {title}
        </Heading>
      </header>
      <div className="flex justify-around">
        <a
          rel="noopener noreferrer"
          id="twitter-profile"
          target="_blank"
          className={`link black dib h2 w2 mr3 hover-${color}`}
          href={twitter}
        >
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="1.414"
          >
            <title>{`${name}'s Twitter`}</title>
            <path
              d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.375-1.337.648-2.085.795-.598-.638-1.45-1.036-2.396-1.036-1.812 0-3.282 1.468-3.282 3.28 0 .258.03.51.085.75C5.152 5.39 2.733 4.084 1.114 2.1.83 2.583.67 3.147.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.416-.02-.617-.058.418 1.304 1.63 2.253 3.067 2.28-1.124.88-2.54 1.404-4.077 1.404-.265 0-.526-.015-.783-.045 1.453.93 3.178 1.474 5.032 1.474 6.038 0 9.34-5 9.34-9.338 0-.143-.004-.284-.01-.425.64-.463 1.198-1.04 1.638-1.7z"
              fillRule="nonzero"
            />
          </svg>
        </a>
        <a
          rel="noopener noreferrer"
          id="linkedin-profile"
          target="_blank"
          className={`link black dib h2 w2 mr3 hover-${color}`}
          href={linkedIn}
        >
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="1.414"
          >
            <title>{`${name}'s LinkedIn`}</title>
            <path
              d="M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964-1.424 1.96v3.778h-2.37V6H8.51V7.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758.614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377zm1.188 8.68H2.37V6h2.376v7.635zM14.816 0H1.18C.528 0 0 .516 0 1.153v13.694C0 15.484.528 16 1.18 16h13.635c.652 0 1.185-.516 1.185-1.153V1.153C16 .516 15.467 0 14.815 0z"
              fillRule="nonzero"
            />
          </svg>
        </a>
      </div>
    </Card>
  )
}

ProfileCard.propTypes = {
  profile: PropTypes.shape({
    image: "",
    name: "",
    title: "",
    twitter: "",
    linkedIn: "",
  }),
}
