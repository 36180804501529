import React from "react"

import bridgeLogo from "../../images/bridge-logo.svg"
import background from "../../images/bridge-holo.jpg"
import { CallToAction } from "../ui/call-to-action.component"

// TODO: Refactor to be more generic usage later
export const HomeHero = ({
  heading,
  devApplicationsOpen,
  designApplicationsOpen,
}) => (
  <header
    className="cover relative"
    style={{
      backgroundImage: `url(${background})`,
      backgroundPosition: "50% 100%",
    }}
  >
    <div className=" pt6-ns pv5 flex flex-column items-center justify-center">
      <div className="w-33-l w-40-m w-60">
        <img src={bridgeLogo} alt="Bridge" />
      </div>
      <h1 className="f3 fw7 purple tc ph4">{heading}</h1>
      <div className="flex flex-row-ns flex-column justify-center">
        {devApplicationsOpen && (
          <CallToAction
            id="partner-cta-top"
            type="internal"
            color="blue"
            margin="mt4 ml4 mr4"
            url="/apply-dev"
          >
            Apply Now
          </CallToAction>
        )}
        {designApplicationsOpen && (
          <CallToAction
            id="partner-cta-top"
            type="internal"
            color="pink"
            margin="mt4"
            bg="bg-white"
            url="/apply-design"
          >
            Apply Now
          </CallToAction>
        )}
      </div>
    </div>
  </header>
)
