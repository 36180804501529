import React from "react"
import nanostyled from "nanostyled"

const Hx = (tag = 3) =>
  nanostyled(`h${tag}`, {
    color: "",
    size: `f${tag}`,
    margin: "mv4",
    fw: "",
    align: "tc",
    base: "open-sans lh-title",
  })

export const Heading = ({ children, tag, ...styleProps }) => {
  const HeaderX = Hx(tag)
  return <HeaderX {...styleProps}>{children}</HeaderX>
}
