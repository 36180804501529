import React from "react"
import nanostyled from "nanostyled"

const AvatarImg = nanostyled("img", {
  width: "w4",
  padding: "pa2",
  margin: "",
  borderColor: "b--green",
  base: "br-100 ba bw1 flex",
})

export const Avatar = ({ src, ...styleProps }) => (
  <AvatarImg alt="" src={src} {...styleProps} />
)
