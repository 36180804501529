import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import { SocialLinks } from "../ui"
import { safelyGetSiteConfig } from "../../utils"

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          navigation {
            label
            linkURL
          }
        }
      }
    }
  }
`

export const FooterComponent = ({ siteLinks }) => (
  <footer className="ph3 ph4-ns pt5 pb3 bt b--black-10 black-70">
    <a
      id="mail-footer"
      href="mailto:hello@bridgeschool.io"
      className="link b f3 f2-ns lh-solid blue hover-light-blue libre-baskerville i"
    >
      hello@bridgeschool.io
    </a>
    <div className="mt5 mb3">
      {siteLinks.map((link, i) => (
        <Link
          key={`sitelink${i}`}
          to={link.linkURL}
          className="f6 dib pr2 blue hover-pink no-underline"
        >
          {link.label}
        </Link>
      ))}
    </div>
    <SocialLinks />
  </footer>
)

export const Footer = () => (
  <StaticQuery
    query={query}
    render={data => {
      const navigation = safelyGetSiteConfig(data.sitePage).navigation || []
      return <FooterComponent siteLinks={navigation} />
    }}
  />
)
