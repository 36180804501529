import React from "react"
import PropTypes from "prop-types"
import ReactYoutubePlayer from "react-player/youtube"

const MAX_VIDEO_WIDTH = 700

const alignmentToClassMap = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
}

const getAlignmentClass = align =>
  alignmentToClassMap[align] || alignmentToClassMap["center"]

export const Video = ({ url, align = "center" }) => (
  <div className={`pv4 flex items-center w-100 ${getAlignmentClass(align)}`}>
    <div className="w-100" style={{ maxWidth: `${MAX_VIDEO_WIDTH}px` }}>
      <div className="aspect-ratio aspect-ratio--16x9">
        <ReactYoutubePlayer
          className="aspect-ratio--object"
          url={url}
          width="100%"
          height="100%"
          controls
        />
      </div>
    </div>
  </div>
)

Video.propTypes = {
  url: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
}
