import React from "react"
import nanostyled from "nanostyled"

const Section = nanostyled("section", {
  color: "",
  bg: "",
  align: "",
  padding: "pv5 pv6-ns ph4 ph5-m ph7-l",
  fontSize: "f5",
  border: "bt b--black-10",
  base: "libre-baskerville center lh-2",
})

export const SectionBlock = ({ children, ...styleProps }) => (
  <Section {...styleProps}>{children}</Section>
)
