import React from "react"
import PropTypes from "prop-types"

import { Attribution, Avatar, Quote, SectionBlock } from "../ui"

export const Testimonial = ({
  image,
  children,
  attribution,
  ...styleProps
}) => {
  return (
    <SectionBlock padding="pv5-ns pv4 ph3 ph7-l" {...styleProps}>
      {image ? <Avatar src={image} margin="mr-auto ml-auto" /> : null}
      <Quote>{children}</Quote>
      <Attribution>{attribution}</Attribution>
    </SectionBlock>
  )
}

Testimonial.propTypes = {
  quote: PropTypes.string,
  image: PropTypes.string,
  attribution: PropTypes.string,
}
