import { navigate } from "gatsby"

import { request } from "./helpers"
import logo from "../images/bridge-logo.svg"

const STRIPE_KEY = process.env.GATSBY_STRIPE_PK || ""
const STRIPE_URL =
  process.env.NODE_ENV === "development"
    ? "/functions/donations"
    : "/.netlify/functions/donations"

const MOCK_STRIPE_CHECKOUT = {
  configure: () => null,
}

// Function that makes the request to our donations URL
// and deals with error navigation
const handleStripePayment = async (token, amount) => {
  try {
    await request(STRIPE_URL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        currency: "CAD",
        amount,
      }),
    })
    navigate("/thank-you")
  } catch (error) {
    navigate("/error")
  }
}

// Singleton payment service class to avoid repeated instantiation
let instance
class PaymentService {
  constructor(amount = 10) {
    this.Stripe = this.initStripe()
    this.amount = amount
  }

  initStripe() {
    // The Stripe configuration object may not always be available, so provide a safe fallback
    const stripeConfig = global.StripeCheckout || MOCK_STRIPE_CHECKOUT
    return stripeConfig.configure({
      key: STRIPE_KEY,
      image: logo,
      locale: "auto",
      token: token => handleStripePayment(token, this.amount),
    })
  }

  openStripe(amount = 1, description = "") {
    this.Stripe.open({
      name: "Bridge Donations",
      description: description,
      zipCode: true,
      amount: this.dollarsToCents(amount),
    })
    this.amount = this.dollarsToCents(amount)
  }

  dollarsToCents(amount) {
    return amount * 100
  }

  closeStripe() {
    this.Stripe.close()
  }
}

instance = instance ? instance : new PaymentService()
export default instance
