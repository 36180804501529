import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import { SectionHeading, Heading } from "../ui"
import { safelyGetSiteConfig } from "../../utils"
import govlLogo from "../../images/government-of-canada-logo.png"

export const query = graphql`
  query {
    sitePage(path: { eq: "/site-config/" }) {
      context {
        frontmatter {
          sponsorshipTiers {
            name
            sponsors {
              logo
              url
              logoSize
            }
          }
        }
      }
    }
  }
`

export const Sponsors = () => (
  <StaticQuery
    query={query}
    render={data => {
      const sponsorshipTiers =
        safelyGetSiteConfig(data.sitePage).sponsorshipTiers || []
      return <SponsorsTemplate sponsorshipTiers={sponsorshipTiers} />
    }}
  />
)

export const SponsorsTemplate = ({ sponsorshipTiers = [] }) => {
  return (
    <section className="bg-white bt b--black-10 pv5 ph4 ph7-l">
      <SectionHeading>Our Sponsors</SectionHeading>
      {sponsorshipTiers.map((tier, i) => (
        <LogoGrid
          hasGovernmentLogo={i === 0}
          title={tier.name}
          sponsors={tier.sponsors}
        />
      ))}
    </section>
  )
}

// TODO: Polyfill Object-Fit contain
const LogoGrid = ({ sponsors, title, hasGovernmentLogo }) => (
  <>
    <Heading tag={3} color="light-blue" fw="fw1" align="tc">
      {title}
    </Heading>
    <hr className={"bg-pink w3 pt1 mt2 mb4 bn dn db-l"} />
    <div className="flex flex-column items-center">
      <div
        style={{ justifyContent: "space-evenly" }}
        className="flex flex-column flex-column items-center flex-row-l flex-wrap w-100"
      >
        {sponsors.map((sponsor, i) => (
          <a
            href={sponsor.url}
            key={i}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex mb2 w-25-l w-50-m w-100 logo h4-ns justify-center items-center`}
          >
            <img
              src={sponsor.logo}
              alt={sponsor.name}
              className="w-80"
              style={{
                objectFit: "contain",
                maxHeight: sponsor.logoSize === "large" ? "6rem" : "4rem",
              }}
            />
          </a>
        ))}
      </div>
      {/* TODO: refactor our logo grid to handle long skinny logos */}
      {hasGovernmentLogo && (
        <img
          className="mw-100-m mw-33-ns mb4 pa3"
          src={govlLogo}
          alt="Government of Canada"
        />
      )}
    </div>
  </>
)

SponsorsTemplate.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      logo: "",
      url: "",
      type: "",
    })
  ),
}
