import React from "react"

import { RenderMarkdown } from "../core"

export const Attribution = ({ children }) => {
  const renderComponents = {
    p: props => <p className="di" {...props} />,
  }
  return (
    <div className="inline-children tc f5">
      &mdash; <RenderMarkdown md={children} components={renderComponents} />
    </div>
  )
}
