import React from "react"

import { ContentSection } from "./content-section.component"
import { withFallback } from "../../utils"

export const THEMES = {
  BLUE: {
    bg: "bg-blue",
    color: "white",
  },
  NEAR_WHITE: {
    bg: "bg-near-white",
    color: "black",
  },
  PURPLE: {
    bg: "bg-purple",
    color: "white",
  },
  WHITE: {
    bg: "bg-white",
    color: "black",
  },
}

export const ContentSections = ({
  sections,
  primaryTheme = THEMES.BLUE,
  secondaryTheme = THEMES.NEAR_WHITE,
}) => (
  <>
    {withFallback(sections, []).map((section, i) => {
      const theme = i % 2 === 0 ? primaryTheme : secondaryTheme
      return (
        <ContentSection
          key={i}
          section={section}
          color={theme.color}
          bg={theme.bg}
        />
      )
    })}
  </>
)
